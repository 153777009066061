import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const WiezijnwePage = ({ data }) => (
  <Layout>
    <SEO title={data.strapiWieZijnWe.meta_titel + " | Wie zijn we"} description={data.strapiWieZijnWe.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row no-gutters cta-wiezijnwe">
        <div className="col-lg-6 d-flex flex-column justify-content-center bg-wervel">
          <div className="cta-wiezijnwe-tekst">
            <h1>{data.strapiWieZijnWe.titel}</h1>
            <ReactMarkdown source={data.strapiWieZijnWe.tekst} />
          </div>
        </div>
        <div className="col-lg-6 cta-wiezijnwe-afb">
          <Img fluid={data.strapiWieZijnWe.afbeelding.childImageSharp.fluid} />
        </div>
      </div>
    </div>
    <div className="container mt-3 mb-md-5">
      <div className="row">
        <div className="col-lg-9">
        <ReactMarkdown source={data.strapiWieZijnWe.tekst2} />
        </div>
      </div>
      <div className="row mt-5">
        {data.allStrapiTeam.edges.sort((a, b) => (a.node.naam > b.node.naam) ? 1 : -1).map(document =>(
          <div className="col-xl-3 col-lg-4 col-md-5 ml-auto mr-auto mb-5 mb-md-0 text-center team" key={document.node.id}>
            <Link to={`/wiezijnwe/${document.node.naam.replace(/\s/g, "").toLowerCase()}`}>
              <Img fluid={document.node.afbeelding.childImageSharp.fluid} className="d-none" />
              <span className="team-afb" style={{ backgroundImage:`url(${document.node.afbeelding.publicURL})` }}></span>
            </Link>
            <h2 className="mt-3">
              <Link to={`/wiezijnwe/${document.node.naam.replace(/\s/g, "").toLowerCase()}`}>
                {document.node.naam}
              </Link>
            </h2>
            <ReactMarkdown source={document.node.intro} />
            <Link to={`/wiezijnwe/${document.node.naam.replace(/\s/g, "").toLowerCase()}`}>
              <span className="btn btn-outline-primary">Lees meer</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)


export default WiezijnwePage

export const pageQuery = graphql`
  query WiezijnweQuery {
    allStrapiTeam {
      edges {
        node {
          id
          naam
          intro
          afbeelding {
            id
            publicURL
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    strapiWieZijnWe {
      titel
      tekst
      tekst2
      meta_titel
      meta_omschrijving
      afbeelding {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`